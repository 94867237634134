
import Vue, { PropType } from "vue";

import { Word } from "@prestonly/preston-common";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { DialogI } from "@/types/dialog";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "LessonGlossaryDialog",
  components: {
    BaseDialog,
    Btn,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  computed: {
    lessonId(): string {
      return this.dialog.config.payload?.lessonId || "";
    },
    words(): Word[] {
      const words = this.$store.getters["word/getWordsForLesson"](this.lessonId);
      return words.sort((a, b) => (a.answer > b.answer ? 1 : -1));
    },
  },

  async created() {
    if (!this.lessonId) {
      return;
    }
    await this.$store.dispatch("word/getWordsForLesson", { lessonId: this.lessonId });
  },
});
